import { connect } from "react-redux";
import {State} from "../../../store";

const mapStateToProps = (_: State) => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps);
